import React, { useState, useEffect } from "react";
import { CpIcon, CpWell, CpToggle, CpButton } from "canopy-styleguide!sofe";
import { InfoTooltip } from "./kba-info-tooltip.component";
import { handleError } from "src/handle-error.helper";
import { getKbaCredits } from "../kba.resource";

const LOW_CREDITS_THRESHOLD = 10;
export const KbaWell = ({
  isTemplateMode,
  setKbaEnabled,
  kbaEnabled,
  creditsSubtracted = 0,
  setHasEnoughKbaCredits,
}) => {
  const [credits, setCredits] = useState(0);
  const [creditsUsedToDate, setCreditsUsedToDate] = useState(0);

  const hasUnlimitedCredits = credits === -1;

  useEffect(() => {
    const sub = getKbaCredits().subscribe(updateKbaCredits, handleError);
    return () => sub.unsubscribe();
  }, []);

  const updateKbaCredits = ({ qty_available, qty_used }) => {
    setCredits(qty_available);
    setCreditsUsedToDate(qty_used);
  };

  useEffect(() => {
    if (hasUnlimitedCredits) {
      setHasEnoughKbaCredits(true);
      return;
    }
    if (creditsSubtracted > credits) {
      setHasEnoughKbaCredits(false);
    }
    if (creditsSubtracted <= credits) {
      setHasEnoughKbaCredits(true);
    }
  }, [credits, creditsSubtracted]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'setHasEnoughKbaCredits'

  const hasPurchasedCreditsBefore = creditsUsedToDate > 0 || credits > 0; // User has purchased credits before (even if credits have since been depleted)
  const showCreditsManageWell = kbaEnabled && hasPurchasedCreditsBefore; // If KBA is toggled on and if credits have been purchased before, show CreditManageWell

  return (
    <div className="cp-mt-16" style={{ borderTop: "1px solid var(--cp-color-app-border)" }}>
      <CpWell className="cp-flex-column cp-mt-16" level={1}>
        <div className="cp-flex-center justify-between cp-m-16">
          <div className="cp-flex-center ">
            <CpIcon className="cp-mr-8" name="misc-kba-shield" fill="var(--cp-color-app-success-text)" />
            <span className="cp-body cp-wt-bold cp-mr-8">Signer Authentication</span>
            <InfoTooltip icon="information-circle-open-small" fill="var(--cp-color-app-icon)" />
          </div>
          <CpToggle disabled={!hasPurchasedCreditsBefore} checked={kbaEnabled} onChange={setKbaEnabled} />
        </div>
        {showCreditsManageWell && (
          <CreditCounter isTemplateMode={isTemplateMode} credits={credits} creditsSubtracted={creditsSubtracted} />
        )}
        {kbaEnabled && !hasUnlimitedCredits && (
          <div className="cp-flex-center justify-center" style={{ borderTop: "1px solid var(--cp-color-app-border)" }}>
            <CpButton
              className="cp-m-8"
              block
              btnType="flat"
              anchor
              target="_blank"
              rel="noopener noreferrer"
              href="#/global-settings/account-management"
            >
              Purchase credits
            </CpButton>
          </div>
        )}
      </CpWell>
      {!hasUnlimitedCredits && showCreditsManageWell && creditsSubtracted > credits && (
        <div className="cp-caption cp-color-app-secondary-text">
          <span className="cp-color-app-warning-text">* </span>Additional credits required
          {isTemplateMode ? " to use this template." : " for eSign request."}
        </div>
      )}
    </div>
  );
};

const CreditCounter = ({ credits, creditsSubtracted, isTemplateMode }) => {
  const hasUnlimitedCredits = credits === -1;
  const creditsLow = !hasUnlimitedCredits && credits < LOW_CREDITS_THRESHOLD;
  const notEnoughCredits = !hasUnlimitedCredits && creditsSubtracted > credits;
  return (
    <div className="cp-flex-center" style={{ borderTop: "1px solid var(--cp-color-app-border)" }}>
      <div
        className="cp-flex-column cp-pv-8 cp-ph-16"
        style={{ flexBasis: "50%", borderRight: "1px solid var(--cp-color-app-border)" }}
      >
        <div className="cp-caption cp-wt-semibold cp-color-app-secondary-text">
          Credits{isTemplateMode ? " needed" : " used"}
        </div>
        <div className="cp-body">{creditsSubtracted}</div>
      </div>
      <div
        className={`cp-flex-column cp-pv-8 cp-ph-16 ${
          creditsLow || notEnoughCredits ? "cp-color-app-warning-text" : ""
        }`}
        style={{ flexBasis: "50%" }}
      >
        <div
          className={`cp-caption cp-wt-semibold ${
            creditsLow || notEnoughCredits ? "cp-color-app-warning-text" : "cp-color-app-secondary-text"
          }`}
        >
          Credits available
        </div>
        <div className="cp-body">{hasUnlimitedCredits ? "Unlimited" : credits}</div>
      </div>
    </div>
  );
};
